const termsModal = document.getElementById('terms-modal');
const termsModalContent = document.getElementById('modal-container');
const termsModalBtn = document.getElementById('terms-modal-btn');
const termsModalClose = document.getElementById('terms-modal-close');

const abrirModal = (e) => {
    e.preventDefault();
    termsModal.style.display = 'flex';
}

const fecharModal = (e) => {
    e.preventDefault();
    termsModal.style.display = 'none';
}

const pararPropagacao = (e) => {
    e.stopPropagation();
}

termsModalBtn.addEventListener('click', abrirModal);
termsModalClose.addEventListener('click', fecharModal);
termsModalContent.addEventListener('click', pararPropagacao);
termsModal.addEventListener('click', fecharModal);

